<div
  class="main_wrapper"
  *ngIf="!pathsToIgnore.includes(currentPath) && artist?.first_name"
>
  <header class="top_bar">
    <div class="artist_name_type">
      <div class="artist_img">
        <img *ngIf="artist?.photo" [src]="artist?.photo" />
        <span *ngIf="!artist?.photo">
          {{ artist?.user_name?.[0] || ''

          }}{{ artist?.user_name?.[1] || '' }}
        </span>
      </div>
      <div class="artist_name">{{ artist?.user_name }}</div>
      <div class="verified_icon">
        <img src="assets/images/check_list.png" />
      </div>

      <div class="btn-group left_dp" dropdown>
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn"
          aria-controls="dropdown-basic"
        >
          <svg
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.68194 0.0913906C9.83151 0.220103 9.84039 0.437032 9.70177 0.575914L5.82485 4.46034C5.37285 4.91322 4.62754 4.91322 4.17554 4.46034L0.298616 0.575913C0.160002 0.437031 0.16888 0.220103 0.318446 0.0913902C0.468011 -0.0373224 0.701626 -0.0290785 0.84024 0.109804L4.71716 3.99423C4.87692 4.1543 5.12347 4.1543 5.28323 3.99423L9.16015 0.109804C9.29877 -0.0290782 9.53238 -0.0373221 9.68194 0.0913906Z"
              fill="white"
            />
          </svg>
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li
            role="menuitem"
            [routerLink]="['/profile']"
            class="cursor-pointer"
          >
            <img src="assets/images/Menu_down.svg" />
            <span class="text-white">My account</span>
          </li>
          <li role="menuitem" (click)="logout()" class="cursor-pointer">
            <img src="assets/images/logout_down.svg" />
            <span class="text-white">Log out</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="search_tab">
      <input type="text" placeholder="Search" />
      <span><img src="assets/images/search_tab.png" /></span>
    </div>
    <div class="need_help">
      <p class="cursor-pointer" (click)="openHelpModel()">Do you need help?</p>
      <span><img src="assets/images/notification.png" /></span>
    </div>
  </header>
  <header class="top_bar_mob">
    <div class="mob_header_name">
      <div class="mob_user_name">
        Hello, <span>{{ artist?.user_name }}</span>
      </div>
      <div
        class="btn-group left_dp"
        dropdown
        #dropdown="bs-dropdown"
        [autoClose]="false"
      >
        <button
          id="button-basic"
          dropdownToggle
          type="button"
          class="btn"
          aria-controls="dropdown-basic"
        >
          <img src="assets/images/Vector_3.png" />
        </button>
        <ul
          id="dropdown-basic"
          *dropdownMenu
          class="dropdown-menu cursor-pointer"
          role="menu"
          aria-labelledby="button-basic"
        >
          <li role="menuitem" [routerLink]="['/profile']">
            <img src="assets/images/Menu_down.svg" />
            <span>My account</span>
          </li>
          <li role="menuitem" (click)="logout()">
            <img src="assets/images/logout_down.svg" />
            <span>Log out</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="mob_notofication">
      <img src="assets/images/Notifications.svg" />
    </div>
  </header>
</div>
