import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_CONFIG } from '@frontend/app-config';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ArtistService {
  ApiUrl!: string;
  private saveLogoutSubject: BehaviorSubject<boolean>;
  public saveLogout$: Observable<boolean>;

  private safeLogoutSubject: BehaviorSubject<boolean>;
  public safeLogout$: Observable<boolean>;

  constructor(
    private http: HttpClient,
    @Inject(APP_CONFIG) private appConfig: any
  ) {
    this.saveLogoutSubject = new BehaviorSubject<boolean>(false);
    this.saveLogout$ = this.saveLogoutSubject.asObservable();

    this.safeLogoutSubject = new BehaviorSubject<boolean>(false);
    this.safeLogout$ = this.safeLogoutSubject.asObservable();
    this.ApiUrl = `${this.appConfig.apiUrl}`;
  }

  updateLogout() {
    this.saveLogoutSubject.next(true);
  }

  updateSaved() {
    this.safeLogoutSubject.next(true);
  }

  updateValues() {
    this.saveLogoutSubject.next(false);
    this.safeLogoutSubject.next(false);
  }

  getArtistProfile(id: string, query: any) {
    return this.http.get(`${this.ApiUrl}users/${id}`, { params: query });
  }

  studioSetup(body: any) {
    return this.http.put(`${this.ApiUrl}users/stdio-setup`, body);
  }

  nameValidity(params: any) {
    return this.http.get(`${this.ApiUrl}users/nameValidity`, { params });
  }

  getPodcasts(params: any) {
    return this.http.get(`${this.ApiUrl}podcasts/artist`, { params });
  }

  getPodcastEpisodes(podcastId: string, isForWeb: any) {
    return this.http.get(`${this.ApiUrl}podcasts/${podcastId}/episodes?isForWeb=${isForWeb}`);
  }

  deletePodcast(podcastId: string) {
    return this.http.delete(`${this.ApiUrl}podcasts/${podcastId}`);
  }

  deleteEpisode(episodeId: string) {
    return this.http.delete(`${this.ApiUrl}podcast-episodes/${episodeId}`);
  }

  getAllCategories(params: any = {}) {
    if (params?.type === 'skit') {
      return this.http.get(`${this.ApiUrl}skits/categories`, { params });
    } else {
      return this.http.get(`${this.ApiUrl}podcasts/categories`, { params });
    }
  }

  addUpdatePodcast(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}podcasts/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}podcasts`, body);
    }
  }

  getPodcastById(podcastId: string, params: any) {
    return this.http.get(`${this.ApiUrl}podcasts/${podcastId}`, { params });
  }

  addUpdateEpisode(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}podcast-episodes/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}podcast-episodes`, body);
    }
  }

  addUpdateAllEpisode(body: any[]) {
    return this.http.post(`${this.ApiUrl}podcast-episodes/all`, body);
  }

  getEpisodeById(podcastId: string, episodeId: string, params: any) {
    return this.http.get(
      `${this.ApiUrl}podcasts/${podcastId}/episodes/${episodeId}`,
      { params }
    );
  }

  updatePodcastTerms(body: string[]) {
    return this.http.post(`${this.ApiUrl}podcasts/all`, body);
  }

  getSkits(params: any) {
    return this.http.get(`${this.ApiUrl}skits`, { params });
  }

  deleteSkit(skit_id: any) {
    return this.http.delete(`${this.ApiUrl}skits/${skit_id}`);
  }

  addUpdateSkit(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}skits/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}skits`, body);
    }
  }

  getSkitById(skitId: string, params: any) {
    return this.http.get(`${this.ApiUrl}skits/${skitId}`, { params });
  }

  updateSkitsTerms(body: string[]) {
    return this.http.post(`${this.ApiUrl}skits/all`, body);
  }

  updateArtist(id: string, body: any) {
    return this.http.put(`${this.ApiUrl}users/${id}`, body);
  }

  verifyPhoneEmail(body: any) {
    if (body.type === 'email') {
      return this.http.post(`${this.ApiUrl}account/profile/add-email`, body);
    } else {
      return this.http.post(`${this.ApiUrl}account/profile/add-phone`, body);
    }
  }

  updatePassword(body: any) {
    return this.http.put(`${this.ApiUrl}account/update/password`, body);
  }

  deleteProfile(id: string) {
    return this.http.put(`${this.ApiUrl}account/delete`, null);
  }

  getMusics(params: any) {
    return this.http.get(`${this.ApiUrl}albums/artist`, { params });
  }

  getMusicTracks(albumId: string, params: any) {
    return this.http.get(`${this.ApiUrl}albums/${albumId}/tracks`, { params });
  }

  deleteMusic(albumId: string) {
    return this.http.delete(`${this.ApiUrl}albums/${albumId}`);
  }

  deleteTrack(trackId: string) {
    return this.http.delete(`${this.ApiUrl}tracks/${trackId}`);
  }

  getAllGenres(params: any = {}) {
    return this.http.get(`${this.ApiUrl}music/genres`, { params });
  }

  addUpdateMusic(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}albums/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}albums`, body);
    }
  }

  getAlbumById(podcastId: string, params: any) {
    return this.http.get(`${this.ApiUrl}albums/${podcastId}`, { params });
  }

  addUpdateTrack(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}tracks/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}tracks`, body);
    }
  }

  addUpdateAllTracks(body: any[]) {
    console.log('this is the body ', body)
    return this.http.post(`${this.ApiUrl}tracks/all`, body);
  }

  getTrackById(albumId: string, trackId: string, params: any) {
    return this.http.get(`${this.ApiUrl}albums/${albumId}/tracks/${trackId}`, {
      params,
    });
  }

  updateAlbumTerms(body: string[]) {
    return this.http.post(`${this.ApiUrl}albums/all`, body);
  }

  getAllBankDetail() {
    return this.http.get(`${this.ApiUrl}/bank`);
  }

  getAllExclusiveContent(params: any) {
    return this.http.get(`${this.ApiUrl}fan-club/get-exclusive-content`, {
      params,
    });
  }

  getAllLiveShows(params: any) {
    return this.http.get(`${this.ApiUrl}fan-club/get-live-show`, { params });
  }

  getAllSubscriptionPlans(params: any) {
    return this.http.get(`${this.ApiUrl}fan-club/subscription/plans`, {
      params,
    });
  }

  getAllSubscriptionPlansArtis(params: any) {
    return this.http.get(
      `${this.ApiUrl}fan-club/subscription/plans/${params.id}`
    );
  }

  getAllDiscounts(params: any) {
    return this.http.get(`${this.ApiUrl}fan-club/get-discount`, {
      params,
    });
  }

  getUrlsData(params: any) {
    return this.http.get(`${this.ApiUrl}fan-club/get-merchandise`, {
      params,
    });
  }

  addUpdateBankDetail(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}bank/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}bank`, body);
    }
  }
  updatePlan(body: any) {
    if (body.update) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-subscription/${body._id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}fan-club/artist-subscription`, body);
    }
  }
  addUpdateLiveShows(body: any) {
    if (body._id) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-live-show/${body._id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}fan-club/create-live-show`, body);
    }
  }

  addUpdateDiscounts(body: any) {
    if (body._id) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-discount/${body._id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}fan-club//create-discount`, body);
    }
  }

  addUpdateClub(body: any) {
    if (body._id) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-events/${body._id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}fan-club/create-events`, body);
    }
  }

  addUpdateMerchandising(body: any, id: string) {
    if (id) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-merchandise/${id}`,
        body
      );
    } else {
      return this.http.post(`${this.ApiUrl}fan-club/create-merchandise`, body);
    }
  }

  addExclusiveContent(
    body: any,
    content_id: string,
    data_id: string,
    dataType: string
  ) {
    if (content_id) {
      return this.http.put(
        `${this.ApiUrl}fan-club/update-exclusive-content/${content_id}/${data_id}/${dataType}`,
        body
      );
    } else {
      return this.http.post(
        `${this.ApiUrl}/fan-club/create-exclusive-content`,
        body
      );
    }
  }
  addFanclubName(body: any) {
    return this.http.put(
      `${this.ApiUrl}/account/profile/update`,
      body
    );
  }
  getEvents(params: any) {
    return this.http.get(`${this.ApiUrl}/fan-club/get-events`, { params });
  }

  deleteBankDetail(bank_id: string) {
    return this.http.delete(`${this.ApiUrl}/bank/${bank_id}`);
  }
  deleteContent(contentId: string, dataId: string, fielType: string) {
    return this.http.delete(
      `${this.ApiUrl}fan-club/remove-exclusive-content/${contentId}/${dataId}/${fielType}`
    );
  }

  getTokens() {
    return this.http.get(`${this.ApiUrl}tokens/get-tokens`);
  }

  getArtisTokens() {
    return this.http.get(`${this.ApiUrl}users/get-tokens`);
  }

  addTokenToCart(body: any) {
    return this.http.post(`${this.ApiUrl}/tokens/add-token-to-cart`, body);
  }

  getBillingDetails(params: any) {
    return this.http.get(`${this.ApiUrl}business-details`, { params });
  }

  deleteBillingDetails(id: string) {
    return this.http.delete(`${this.ApiUrl}business-details/${id}`);
  }

  addUpdateBillinDetails(body: any) {
    if (body?._id) {
      return this.http.put(`${this.ApiUrl}business-details/${body?._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}business-details`, body);
    }
  }

  getTransactionHistory(params: any) {
    console.log('params', params)
    let paramsData = params.params
    return this.http.get(`${this.ApiUrl}users/wallet-history`, { params: paramsData });
  }

  getFansList(params: any) {
    return this.http.get(`${this.ApiUrl}users/${params}/fans`);
  }

  giveAwayToken(body: any) {
    return this.http.post(`${this.ApiUrl}users/give-away-tokens`, body);
  }
  withdrawAmount(body: any) {
    return this.http.post(`${this.ApiUrl}users/with-draw-amount`, body);
  }

  paymentConfirm(body: any) {
    return this.http.post(`${this.ApiUrl}tokens/buy-token`, body);
  }

  getPaymentMethods() {
    return this.http.get(`${this.ApiUrl}tokens/payment-method`);
  }

  getStatsData() {
    return this.http.get(`${this.ApiUrl}fan-club/dashboard`);
  }

  getMembersList() {
    return this.http.get(`${this.ApiUrl}fan-club/members`);
  }

  getReasons() {
    return this.http.get(`${this.ApiUrl}contact/reasons`);
  }

  postContactDetails(body: any) {
    return this.http.post(`${this.ApiUrl}contact`, body);
  }

  updateNotification(body: any) {
    return this.http.post(`${this.ApiUrl}notification-control`, body);
  }

  getNotifications() {
    return this.http.get(`${this.ApiUrl}notification-control`);
  }

  GenerateRtmTokens(params: any) {
    return this.http.get(`${this.ApiUrl}public/rtmToken`, { params });
  }

  getArtistDashBoard(params: any) {
    return this.http.get(`${this.ApiUrl}users/artistDashBoard`, {params});
  }

  removeMerchandise(merchandise_id: string) {
    return this.http.delete(`${this.ApiUrl}fan-club/remove-merchandise/${merchandise_id}`);
  }

  getPlatformCommission(params: any) {
    return this.http.get(`${this.ApiUrl}users/getPlatformCommission`, {params});
  }

  getAllRadioStations(params: any) {
    return this.http.get(`${this.ApiUrl}radio-stations`, { params });
  }

  getRadioStationById(id: string, query: any) {
    return this.http.get(`${this.ApiUrl}radio-stations/${id}`, {params: query,});
  }

  addUpdateRadioStation(body: any) {
    if (body._id) {
      return this.http.put(`${this.ApiUrl}radio-stations/${body._id}`, body);
    } else {
      return this.http.post(`${this.ApiUrl}radio-stations`, body);
    }
  }

  deleteRadioStation(id: string) {
    return this.http.delete(`${this.ApiUrl}radio-stations/${id}`);
  }

}
